import "./App.css";
import Header from "./components/Header";
import Swap from "./components/Swap"
import Tokens from "./components/Tokens";
import { Routes, Route } from "react-router-dom";
import { useConnect, useAccount } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";

function App() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });

  return (
    <div className="App">
      <Header connect={connect} isConnected={isConnected} address={address} />
      <div className="holder">
        <div className="container">
          <h1>BEGINNEN SIE MIT DEM HANDEL VON KRYPTOWÄHRUNGEN MIT <span className="first">SWI</span><span className="second">SWAP</span></h1>
          <div className="mainWindow">
            <Routes>
              <Route path="/" element={<Swap isConnected={isConnected} address={address} />} />
              <Route path="/tokens" element={<Tokens />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;