import React, { useState, useEffect } from "react";
import { Input, Popover, Radio, Modal, message } from "antd";
import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import tokenList from "../tokenList.json";
import axios from "axios";
import { useSendTransaction, useWaitForTransaction } from "wagmi";




function Swap(props) {
  const { address, isConnected } = props;
  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(tokenList[2]);
  const [tokenTwo, setTokenTwo] = useState(tokenList[8]);
  const [isOpen, setIsOpen] = useState(false);
  const [changToken, setChangeToken] = useState(1);
  const [prices, setPrices] = useState(null);
  const [txDetails, setTxDetails] = useState({
    to:null,
    data: null,
    value: null,
  }); 

  const {data, sendTransaction} = useSendTransaction({
    request: {
      from: address,
      to: String(txDetails.to),
      data: String(txDetails.data),
      value: String(txDetails.value),
    }
  })


  function handleSlippageChange(e) {
    setSlippage(e.target.value);
  }

  function changeAmount(e) {
    setTokenOneAmount(e.target.value);
    if(e.target.value && prices){
      setTokenTwoAmount((e.target.value * prices.ratio).toFixed(2))
    }else{
      setTokenTwoAmount(null);
    }
  }

  function switchTokens() {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    fetchPrices(two.address, one.address);
  }

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);

  }

  function modifyToken(i) {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changToken === 1) {
      setTokenOne(tokenList[i]);
      fetchPrices(tokenList[i].address, tokenTwo.address)
    } else {
      setTokenTwo(tokenList[i]);
      fetchPrices(tokenOne.address, tokenList[i].address)

    }
    setIsOpen(false);

  }

  async function fetchPrices(one, two){

      const res = await axios.get('http://localhost:3001/tokenPrice', {
        params: {addressOne: one, addressTwo: two}
      })
     
      
      setPrices(res.data)
  }

  async function fetchDexSwap(){

    const allowance = await axios.get('https://api.1inch.io/v5.0/1/approve/allowance?tokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&walletAddress=0xFa7C106E2623f7d10FcA7C27096a80754f0dB4f9')
    
    if (allowance.data.allowance === "0"){
       const approve = await axios.get('https://api.1inch.io/v5.0/1/approve/transaction?tokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48')
       setTxDetails(approve.data);
       console.log("not approved")
       return
    }

    const tx = await axios.get(
      'https://api.1inch.io/v5.0/1/swap?fromTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&toTokenAddress=0x111111111117dc0aa78b770fa6a738034120c302&amount=1000000&fromAddress=0xFa7C106E2623f7d10FcA7C27096a80754f0dB4f9&slippage=1'
    )

    setTxDetails(tx.data.tx);
  
  }

  useEffect(()=>{

    fetchPrices(tokenList[0].address, tokenList[1].address)

  }, [])

  useEffect(()=>{

      if(txDetails.to && isConnected){
        sendTransaction();
      }
  }, [txDetails])

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );

  return (
    <>
    <Modal
     open={isOpen}
     footer={null}
     onCancel={() => setIsOpen(false)}
     title="Select a token"
    >
      <div className="modalContent"> 
        {tokenList?.map((e, i) => {
          return (
            <div 
              className="tokenChoice"
              key={i}
              onClick={() => modifyToken(i)}     
            >
              <img src={e.img} alt={e.ticker} className="tokenLogo" />
              <div className="tokenChoiceNames">
               <div className="tokenName">{e.name}</div>
               <div className="tokenTicker">{e.ticker}</div>
              </div>
            </div>


          );

        })}
      </div>
    </Modal>

    <div className="tradeBox">
      <div className="tradeBoxHeader"> 
       {/* <h4>SWAP</h4> */}
       <h4>Tauschen</h4>
       <Popover
         content={settings}
         title="Settings"
         trigger="click"
         plasement="bottonRight"
        >
         <SettingOutlined className="cog" />
       </Popover>
      </div>
      <div className="inputs">
        <Input placeholder="0" value={tokenOneAmount} onChange={changeAmount} />
        <Input placeholder="0" value={tokenTwoAmount} disabled={true} />
        <div className="switchButton" onClick={switchTokens}>
         <ArrowDownOutlined className="switchArrow" />
        </div>

        <div className="assetOne" onClick={() => openModal(1)}>
          <img src={tokenOne.img} alt="assetOneLogo" className="assetLogo" />
          {tokenOne.ticker}
          <DownOutlined />
        </div>
        <div className="assetTwo" onClick={() => openModal(2)}>
          <img src={tokenTwo.img} alt="assetOneLogo" className="assetLogo" />
          {tokenTwo.ticker}
          <DownOutlined />
        </div>
      </div>
      {/* <div className="swapButton" disabled={!tokenOneAmount || !isConnected} onClick={fetchDexSwap}>Swap</div> */}
      <div className="swapButton" disabled={!tokenOneAmount || !isConnected} onClick={fetchDexSwap}>Tauschen</div>
    </div>
    </>
  );
}

export default Swap;