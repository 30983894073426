import React from 'react'
import { Link } from "react-router-dom";


function Header(props) {
  const {address, isConnected, connect} = props;

  return (
    <header> 
      <div className='container'>
        <div className="leftH">
          <img src="assets/logo.svg" alt="logo" className="logo" />
          <Link to="/" className="link">
            {/* <div className="headerItem">Swap</div> */}
            <div className="headerItem">Tauschen</div>
          </Link>
          <Link to="/Tokens" className="link">
            {/* <div className="headerItem">Tokens</div> */}
            <div className="headerItem">Token</div>
          </Link>
        </div>
        <div className="rightH">
          < div className="headerItem">
            <img src="assets/eth.svg" alt="eth" className="eth" />
            Ethereum
          </div>
          <div className="connectButton" onClick={connect}>
            {/* {isConnected ? (address.slice(0,4) +"..." +address.slice(38)) : "connect"}           */}
            {isConnected ? (address.slice(0,4) +"..." +address.slice(38)) : "Verbinden Sie sich"}          
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header